<template>
  <el-row :gutter="15" id="workbench">
    <el-col :span="9" class="chunkBox1">
      <div class="chunkBox">
        <div class="chunkTit chunkTitBox">日历</div>
        <div class="h100">
          <div class="flex_b_c">
            <div class="flex_l_c flex1">
              <el-date-picker class="month" size="mini"
                              value-format="yyyy-MM"
                              format="yyyy-MM"
                              v-model="form.currentTime"
                              type="month"
                              placeholder="请选择时间" :clearable="false">
              </el-date-picker>
              <el-select class="m_r1" size="mini" v-model="form.localSystem" clearable placeholder="请选择系统"
                         @change="getList()">
                <el-option
                    v-for="item in this.$dictUtils.getDictList('local_system')"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <el-button size="mini" @click="toToday">今天</el-button>
          </div>
          <div class="calendarBox">
            <el-calendar v-model="form.currentTime">
              <template slot="dateCell" slot-scope="{date, data}">
                <div :class="['dateItem', getDay(data) ? 'matter' : '']">
                  {{ data.day.split('-').slice(2).join('') }}
                  <div class="todayIcon">今</div>
                </div>
              </template>
            </el-calendar>
          </div>
        </div>
      </div>
    </el-col>
    <el-col :span="15" class="chunkBox1">
      <div class="chunkBox">
        <div class="flex_b_c chunkTitBox">
          <div class="chunkTit">业务待办</div>
          <div class="flex_r_c moreBtn" @click="more()">
            <div>更多</div>
            <div class="el-icon-arrow-right"></div>
          </div>
        </div>
        <div class="agentBox">
          <div class="flex_l_c">
            <div :class="['tabItem', form.eventStatus == '0' ? 'selTab':'']" @click="selTab('0')">我的待办</div>
            <div :class="['tabItem', form.eventStatus == '1' ? 'selTab':'' ]" @click="selTab('1')">我的已办</div>
          </div>
          <el-table :data="dataList" size="small" class="table" v-if="dataList.length">
            <el-table-column type="index" label="序号"/>
            <el-table-column prop="incidentDescription" label="业务描述" show-overflow-tooltip/>
            <el-table-column prop="incidentStatus" label="事件状态" show-overflow-tooltip></el-table-column>
            <el-table-column prop="businessType" label="业务模块" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ $dictUtils.getDictLabel("business_module", scope.row.businessType, '') }}
              </template>
            </el-table-column>
            <el-table-column prop="localSystem" label="所在系统" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ $dictUtils.getDictLabel("local_system", scope.row.localSystem, '') }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作">
              <template slot-scope="scope">
                <div class="disposeBtn" v-if="hasPermission('workbench:management:dispose') && form.eventStatus == '0'"
                     @click="toDispose(scope.row)">处理
                </div>
                <div class="disposeBtn" v-if="hasPermission('workbench:management:view') && form.eventStatus == '1'"
                     @click="toDispose(scope.row,1)">详情
                </div>
                <div class="disposeBtn" v-if="hasPermission('workbench:management:del') && form.eventStatus == '1'"
                     @click="delMatter(scope.row.id)">删除
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="noDataBox" v-else>
            <img src="../../../assets/img/noData2.png" class="noData">
            <div>暂无待办业务</div>
          </div>
        </div>
      </div>
    </el-col>
    <el-col :span="8" class="chunkBox2">
      <div class="chunkBox">
        <div class="flex_b_c chunkTitBox">
          <div class="chunkTit">快捷入口</div>
          <div class="flex_c_c managementBtn" @click="management()">
            <div class="el-icon-setting"></div>
            <div>管理</div>
          </div>
        </div>
        <div class="menuItemBox">
          <el-tooltip effect="dark" :content="item.name" placement="top" v-for="(item, index) in menuList" :key="index">
            <div class="menuItem" @click="selMenu(item)">
              <div class="flex_l_c">
                <div :class="['menuIcon', item.icon, 'menuIcon'+index]"></div>
                <div class="omit menuName">{{ item.name }}</div>
              </div>
            </div>
          </el-tooltip>
          <div class="noDataBox" v-if="!menuList.length">
            <img src="../../../assets/img/noData.png" class="noData">
            <div>暂无快捷入口</div>
          </div>
        </div>
      </div>
    </el-col>
    <el-col :span="8" class="chunkBox2">
      <div class="chunkBox">
        <div class="chunkTit">我发起的</div>
        <div class="noteBox" v-if="noteList.length || !sponsorFlag">
          <div class="flex_b_c item" v-for="(item, index) in noteList" :key="index">
            <div class="flex_l_c info">
              <div class="num">{{ index + 1 }}</div>
              <el-tooltip class="flex1" effect="dark" :content="item.noteContent" placement="top-start" v-if="!item.editFlag">
                <div class="flex1 omit noteContent" @click="editNote(item, index)">{{ item.noteContent }}</div>
              </el-tooltip>
              <el-input v-focus class="flex1" ref="note" v-model.trim="item.noteContent" size="mini" maxlength="30"
                        placeholder="请输入" @blur="noteBlur(item)" v-else></el-input>
            </div>
            <div class="el-icon-delete" @click.stop="del(item.id)"></div>
          </div>
        </div>
        <div class="flex_b_c contentBox" v-if="!sponsorFlag">
          <el-input class="flex1 ipt" v-model.trim="content" size="small" maxlength="30" placeholder="请输入发起的内容"
                    clearable></el-input>
          <div class="flex_r_c sponsorBtn" @click="sponsor()">
            <div class="el-icon-plus"></div>
            <div>发起</div>
          </div>
        </div>
        <div class="noDataBox" v-if="!noteList.length && sponsorFlag">
          <img src="../../../assets/img/noData2.png" class="noData">
          <div>暂无业务事项</div>
          <div class="sponsorBtn2" v-if="sponsorFlag" @click="sponsorFlag = false">
            <span class="el-icon-plus"></span>
            <span>发起</span>
          </div>
        </div>
      </div>
    </el-col>
    <el-col :span="8" class="chunkBox2">
      <div class="chunkBox">
        <div class="flex_b_c">
          <div class="chunkTit">数字资源最新上传记录</div>
          <div v-if="hasPermissionButton('workbench:management:records')" class="flex_c_c managementBtn2" @click="recordsOption()">
            <div class="el-icon-setting"></div>
          </div>
        </div>
        <vue-seamless-scroll :class-option="defineScroll" :data="uploadList" class="noteBox" style="height: 85%;overflow: hidden" v-if="uploadList.length">
          <div :class="['item',item.overflow ? 'effusion' : '' ]" style="position: relative" v-for="(item, index) in uploadList" :key="index">
            <div ref="uploadItem" class="omit">{{ item.recode }}</div>
          </div>
        </vue-seamless-scroll>
        <div class="noDataBox" v-if="!uploadList.length">
          <img src="../../../assets/img/noData2.png" class="noData">
          <div>暂无上传记录</div>
        </div>
      </div>
    </el-col>
    <QuickAccess ref="quickAccess" @refreshDataList="getMenuList()"></QuickAccess>
    <AuditorForm ref="auditorForm" @refreshDataList="getList()"></AuditorForm>
    <LendAuditorForm ref="lendAuditorForm" @refreshDataList="getList()"></LendAuditorForm>
    <InteriorAuditorForm ref="interiorAuditorForm" @refreshDataList="getList()"></InteriorAuditorForm>
    <ViewThreeForm ref="viewThreeForm" @refreshDataList="getList()"></ViewThreeForm>
    <ViewForm ref="viewForm" @refreshDataList="getList()"></ViewForm>
    <coll-list ref="collList" @refresh="getList()"></coll-list>
    <upload-record-user-table ref="userTable" @refresh="getUpload()"></upload-record-user-table>
  </el-row>
</template>

<script>
import QuickAccess from './quickAccess'
import AuditorForm from '../record/borrow/electronic/auditorForm'
import LendAuditorForm from '../record/borrow/entity/lend/auditorForm'
import InteriorAuditorForm from '../record/borrow/entity/interior/auditorForm'
import ViewThreeForm from '../dyyg/gather/resource/three/viewThreeForm' // 三维查看弹窗
import ViewForm from '../dyyg/gather/resource/components/viewForm'
import CollList from "@/views/modules/dyyg/libraryManagement/collList.vue";
import UploadRecordUserTable from "@/views/modules/workbench/uploadRecordUserTable.vue"; // 查看弹窗

export default {
  components: {
    UploadRecordUserTable,
    CollList, ViewThreeForm, ViewForm, AuditorForm, LendAuditorForm, InteriorAuditorForm, QuickAccess},
  data() {
    return {
      form: {
        currentTime: '',
        localSystem: '',
        eventStatus: '0',
        current: 1,
        size: 3
      },
      matterData: [],
      dataList: [],
      menuList: [],
      noteList: [],
      uploadList: [],
      content: '',
      sponsorFlag: true,
    }
  },
  //定义计算属性，默认执行一次（监听，类似于data概念）；
  computed: {
    defineScroll() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  watch: {
    'form.currentTime'(val) {
      this.form.currentTime = this.$publicUtile.formatDate(val, 1)
      this.getList()
    },
  },
  mounted() {
    let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
    if (listSearch) {
      this.form.eventStatus = listSearch.eventStatus
      sessionStorage.removeItem('listSearch')
    }
    this.form.currentTime = this.$publicUtile.formatDate('', 1)
    this.getSponsor()
    this.selectByDate()
    this.getMenuList()
    this.getList()
    this.getUpload()
  },
  methods: {
    getList() {
      this.$axios(this.api.auth.commonBacklog, this.form).then((res) => {
        if (res.status) {
          this.dataList = res.data.records
        }
      })
    },

    // 今天
    toToday() {
      this.form.currentTime = this.$publicUtile.formatDate('', 1)
      this.getList()
    },
    // 获取所有有待办事项的日期
    selectByDate() {
      this.$axios(this.api.auth.selectByDate).then((res) => {
        if (res.status) {
          this.matterData = res.data
        }
      })
    },
    // 高亮有事项的日期
    getDay(data) {
      let day = this.matterData.filter(item => item == data.day)
      if (day.length) {
        return true
      } else {
        return false
      }
    },
    // 待办已办
    selTab(val) {
      this.form.eventStatus = val
      this.getList()
    },
    // 更多
    more() {
      this.$router.push({path: '/workbench/backlog'})
    },
    // 去处理
    toDispose(row, num) {
      let see = true
      if (num) {
        see = num
      }
      if (row.businessType != 16 && row.businessType != 17 && row.businessType != 18 && row.businessType != 21) {
        //缓存搜索信息
        let listSearch = {
          eventStatus: this.form.eventStatus,
        }
        sessionStorage.setItem('listSearch', JSON.stringify(listSearch))
      }
      row.id = row.businessId
      if (row.businessType == 0) { //藏品登记审核
        this.$router.push({
          path: '/collection/accounts/addCollection',
          query: {
            butType: 1,
            id: row.id,
            archivesBasicDataId: row.archivesBasicDataId,
            typePage: 'toExamine',
            see: see
          }
        })
      }
      if (row.businessType == 1) { //辅助账审核
        this.$router.push({
          path: '/collection/accounts/addCollection',
          query: {
            butType: 1,
            id: row.id,
            archivesBasicDataId: row.archivesBasicDataId,
            typePage: 'accountExamine',
            see: see
          }
        })
      }
      if (row.businessType == 2) { //藏品总账审核
        this.$router.push({
          path: '/collection/accounts/addCollection',
          query: {
            butType: 1,
            id: row.id,
            archivesBasicDataId: row.archivesBasicDataId,
            typePage: 'ledgerReview',
            see: see
          }
        })
      }
      if (row.businessType == 3) { //征集登记审核
        this.$router.push({
          path: '/collection/collect/addRegistration',
          query: {butType: 5, pageSource: 'toExamine', id: row.id, see: see}
        })
      }
      if (row.businessType == 4) { //征集入馆审核
        this.$router.push({
          path: '/collection/accounts/addCollection',
          query: {
            butType: 8,
            id: row.id,
            archivesBasicDataId: row.archivesBasicDataId,
            typePage: 'entryReview',
            see: see
          }
        })
      }
      if (row.businessType == 5) { //不可移动文物审核
        this.$router.push({
          path: '/collection/immovable/addMovable',
          query: {butType: 3, id: row.id, typePage: 'ImmovableAuditing', see: see}
        })
      }
      if (row.businessType == 6) { //总账注销审核
        this.$router.push({
          path: '/collection/business/collectionDet',
          query: {
            butType: 3,
            id: row.id,
            archivesBasicDataId: row.archivesBasicDataId,
            typePage: 'cancellationReview',
            see: see
          }
        })
      }
      if (row.businessType == 7) { //辅助账注销审核
        this.$router.push({
          path: '/collection/business/collectionDet',
          query: {
            butType: 3,
            id: row.id,
            archivesBasicDataId: row.archivesBasicDataId,
            typePage: 'auxiliaryExamine',
            see: see
          }
        })
      }
      if (row.businessType == 8) { //展览审核
        this.$router.push({
          path: '/collection/business/exhibitionDetail',
          query: {butType: 4, exhibitionId: row.id, see: see},
        })
      }
      if (row.businessType == 9) { //藏品鉴定专家审核
        this.$router.push({
          path: '/collection/business/expertDatabase/addAppraisal',
          query: {butType: 4, expertId: row.id, see: see},
        })
      }
      if (row.businessType == 10) { //藏品鉴定登记审核
        this.$router.push({
          path: '/collection/business/collIdentification/addIdentify',
          query: {butType: 4, appraisalId: row.id, see: see},
        })
      }
      if (row.businessType == 11) { //修复登记审核
        this.$router.push({
          path: '/collection/business/repair/addRepair',
          query: {butType: 4, id: row.id, see: see},
        })
      }
      if (row.businessType == 12) { //事故登记审核
        this.$router.push({
          path: '/collection/business/fault/addFault',
          query: {butType: 4, id: row.id, see: see},
        })
      }
      if (row.businessType == 13) { //复仿制品登记审核
        this.$router.push({
          path: '/collection/business/products/addProducts',
          query: {
            butType: 3,
            id: row.id,
            archivesBasicDataId: row.archivesBasicDataId,
            typePage: 'productsExamine',
            see: see
          }
        })
      }
      if (row.businessType == 14) { //藏品装裱登记审核
        this.$router.push({
          path: '/collection/business/mount/addMount',
          query: {butType: 4, id: row.id, see: see},
        })
      }
      if (row.businessType == 15) { //保护情况登记审核
        this.$router.push({
          path: '/collection/business/protect/addProtect',
          query: {butType: 4, id: row.id, see: see},
        })
      }
      if (row.businessType == 16) { //电子借阅审核
        this.$refs.auditorForm.init(row.businessId, [], 'workbench')
      }
      if (row.businessType == 17) { //借出利用审核
        this.$refs.lendAuditorForm.init(row.businessId, [], 'workbench')
      }
      if (row.businessType == 18) { //内部利用审核
        this.$refs.interiorAuditorForm.init(row.businessId, [], 'workbench')
      }
      if (row.businessType == 19) { //入库审核
        this.$router.push({
          path: '/collection/business/warehousing/shenDetail',
          query: {butType: 4, handleType: 1, id: row.id, switch: 1, see: see}
        })
      }
      if (row.businessType == 20) { //出库审核
        this.$router.push({
          path: '/collection/business/outBound/addOutbound',
          query: {butType: 4, id: row.id, switch: '1', see: see}
        })
      }
      if (row.businessType == 21) { //资产审核
        if (row.resourceType == 0) {
          this.$refs.viewThreeForm.init(row, null, null, num ? '' : 'workbench');
        } else {
          this.$refs.viewForm.init(row.id, row.resourceType, '958321248549470208', null, null, num ? '' : 'workbench');
        }
      }
      if (row.businessType == 22) { //专题库审核
        this.$refs.collList.init(2, row, '', '', '', see)
      }
    },
    // 删除
    delMatter(id) {this.$confirm(`确定删除所选项吗?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios(this.api.auth.commonBacklogRemoveById + '/' + id, {}, 'delete').then(data => {
        if (data.status) {
          this.$message.success('删除成功')
          this.getList()
        } else {
          this.$message.error('删除失败')
        }
      })
    })

    },
    getMenuList() {
      this.$axios(this.api.auth.getSysMenuShortcutKeysByCreateBy).then((res) => {
        if (res.status) {
          this.menuList = res.data
        }
      })
    },
    // 管理
    management() {
      this.$refs.quickAccess.init()
    },
    selMenu(item) {
      this.$router.push({
        path: item.href,
      })
    },
    // 获取我发起的
    getSponsor() {
      this.$axios(this.api.auth.commonBacklogNoteList).then((res) => {
        if (res.status) {
          this.noteList = res.data
          if (this.noteList.length) {
            this.sponsorFlag = false
            this.noteList.map(item => {
              this.$set(item, 'editFlag', false)
            })
          } else {
            this.sponsorFlag = true
          }
        }
      })
    },
    // 发起
    sponsor() {
      if (!this.content) {
        this.$message.info('请输入发起的内容');
        return
      }
      this.$axios(this.api.auth.commonBacklogNoteSave, {concent: this.content}, 'get').then((res) => {
        if (res.status) {
          this.content = ''
          this.$message.success('发起成功');
          this.getSponsor()
        } else {
          this.$message.error('发起失败');
        }
      })
    },
    // 编辑
    editNote(item) {
      this.$set(item, 'editFlag', true)
    },
    noteBlur(item) {
      if (!item.noteContent) {
        this.del(item.id)
      } else {
        this.$axios(this.api.auth.commonBacklogNoteUpdateById, {
          id: item.id,
          noteContent: item.noteContent
        }, 'put').then(data => {
          if (data.status) {
            this.$set(item, 'editFlag', false)
            this.$message.success('修改成功')
            this.getSponsor()
          } else {
            this.$message.error('修改失败')
          }
        })
      }
    },
    // 删除
    del(id) {
      this.$confirm(`确定删除当前数据吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.auth.commonBacklogNoteRemoveById + '/' + id, {}, 'delete').then(data => {
          if (data.status) {
            this.$message.success('删除成功')
            this.getSponsor()
          } else {
            this.$message.error('删除失败')
          }
        })
      }).catch(() => {
        this.getSponsor()
      });
    },

    //数字资源最新上传记录
    getUpload() {
      this.$axios(this.api.auth.uploadRecode, {}, 'get').then(data => {
        if (data.status) {
          this.uploadList = data.data
          //判断文字是否超出范围
          // this.$nextTick(() => {
          //   let uploadItem = this.$refs.uploadItem
          //   if (!this.uploadList || !this.uploadList.length) return
          //   this.uploadList.forEach((item, index) => {
          //     if (uploadItem[index].scrollWidth > uploadItem[0].clientWidth) {
          //       this.$set(item, 'overflow', true)
          //     } else {
          //       this.$set(item, 'overflow', false)
          //     }
          //     console.log(this.uploadList)
          //   })
          // })
        }
      })
    },

    //数字资源最新上传记录设置
    recordsOption(){
       this.$refs.userTable.init()
    },
  },
}
</script>

<style scoped lang="scss">
.effusion:hover{
  div{
    position: absolute;
    padding: 0 1.2rem;
    left: 0;
    animation: sideways 12s linear infinite;
  }
}

@keyframes sideways{
  0%{
    transform: translateX(calc(100% - 400px));
  }
  100%{
    transform: translateX(-100%);
  }
}
</style>
